// 公用接口
import http from '../http';
//获取列表
export function customerGet (params) {
    return http({
        url: "/api/customer/customer",
        method: "GET",
        params:params
    })
}
