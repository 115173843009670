// 添加询单
import http from '../http';

// 添加
export function addInquiryPut (data) {
    return http({
        url: "/api/order/newOrder",
        method: "PUT",
        data:data
    })
}

// 编辑
export function addInquiryEdit (data) {
    return http({
        url: "/api/order/newOrder",
        method: "POST",
        data:data
    })
}
