<template>
  <div>
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>添加询单</span>
      </div>
      <div>
        <template v-if="$store.state.currentPermissions==3">
          <el-button type="primary" @click="subClick">提交</el-button>
        </template>
        <template v-if="$store.state.currentPermissions!=3">
          <el-button type="primary" @click="subClick">提交</el-button>
        </template>
      </div>
    </div>
    <div class="content-main">

      <div class="content-main-title">
        <span>询单信息</span>
        <div class="hr"></div>
      </div>
      <div class="inquiryIn-formation-form">
        <!--<el-form :model="ruleForm" :rules="rules" ref="ruleForm">-->
        <ul>
          <!--  <li>
              <span>询单编号</span>
              <el-input v-model="seek_no" placeholder="请输入订单编号"></el-input>
            </li>-->
          <li>
            <span>客户名称</span>
            <el-autocomplete
                v-model="customer_name"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="handleSelect"
            >
              <template slot-scope="{ item }">
               <!-- customer_name: '',//客户名称
                contacts: '',//联系人
                job: '',//职务
                phone: '',//手机号
                wx: '',//QQ/微信
                email: '',//邮箱-->
                <div style="text-overflow: ellipsis; overflow: hidden; line-height: 32px">
                  {{ item.customer_name }}
                  <div style="text-overflow: ellipsis; overflow: hidden; font-size: 12px;color: #b4b4b4;">手机号:{{ item.phone }}，职务:{{item.job}}</div>
                </div>
              </template>
            </el-autocomplete>
          </li>
          <!--   <li>
               <span>客户名称</span>
               <el-input v-model="customer_name" placeholder="请输入客户名称"></el-input>
             </li>-->
          <li>
            <span>截止时间</span>
            <el-date-picker
                v-model="end_time"
                type="date"
                placeholder="选择截止"
                value-format="timestamp"
            >
            </el-date-picker>
          </li>
          <li>
            <span>联系人</span>
            <el-input v-model="contacts" placeholder="请输入联系人"></el-input>
          </li>
          <li v-if="$store.state.currentPermissions==2">
            <span>销售员</span>
            {{ xiaoshouName }}
          </li>
          <li v-if="$store.state.currentPermissions!=2">
            <span>销售员</span>
            <el-select v-model="saleman_id" placeholder="请选择">
              <el-option
                  v-for="item in saleman_list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            <!--<el-input v-model="saleman_id" placeholder="请输入销售员"></el-input>-->
          </li>
          <li>
            <span>职务</span>
            <el-input v-model="job" placeholder="请输入职务"></el-input>
          </li>

          <li>
            <span>手机号</span>
            <el-input v-model="phone" placeholder="请输入手机号"></el-input>
          </li>
          <li>
            <span>QQ/微信</span>
            <el-input v-model="wx" placeholder="请输入QQ/微信"></el-input>
          </li>
          <li>
            <span>邮箱</span>
            <el-input v-model="email" placeholder="请输入邮箱"></el-input>
          </li>
          <li>
            <span>采购员</span>
            <el-button type="primary" @click="buyerSelectDialog=true">选择</el-button>
            <ul class="inquiryIn-formation-buyer">
              <template v-for="(item,key) in buyer_list">
                <li :key="key" v-if="buyer_ids.indexOf(item.id)!==-1">
                  <span>{{ item.name }}</span>
                  <i class="el-icon-close" @click="buyerIsSelect(item.id)"></i>
                </li>
              </template>
            </ul>
          </li>
        </ul>
      </div>

      <div class="content-main-title">
        <span>询单截图</span>
        <div class="hr"></div>
      </div>
      <div class="screenshot-upload">
        <el-checkbox v-model="screenshotChecked" class="mb-12" v-show="false">采购可见</el-checkbox>
        <!--
        action 上传接口地址
        list-type 文件列表的类型：文件上传后怎么布局
        on-preview 点击文件列表中已上传的文件时的钩子
        :before-remove 删除之前提示
        on-remove 文件列表移除文件时的钩子
        auto-upload 是否在选取文件后立即进行上传
        before-upload 上传文件之前的钩子  判断是否大于小于等
        multiple 是否支持多选文件
        limit 最多可以传多少个
        on-exceed 文件超出个数限制时的钩子
        -->
        <el-upload
            :action="screenshotAction"
            list-type="picture-card"
            :on-preview="screenshotPreview"
            :on-remove="screenshotRemove"
            :auto-upload="true"
            :before-upload="screenshotUpload"
            :on-error="screenshotError"
            :on-success="screenshotSuccess"
            :multiple="false"
            :limit="9"
            :on-exceed="screenshotExceed"
            name="file"
            :data="screenshotData"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="screenshotDialog" :append-to-body="true" class="no-dialog">
          <img width="100%" :src="screenshotImg" alt="">
        </el-dialog>
      </div>

      <div class="content-main-title">
        <span>询单备注</span>
        <div class="hr"></div>
      </div>
      <div>
        <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            placeholder="请输入内容"
            v-model="desc_saleman">
        </el-input>
      </div>


      <div class="content-main-title">
        <span>询单附件</span>
        <div class="hr"></div>
      </div>
      <div class="annex-upload">
        <el-checkbox v-model="annexChecked" class="mb-12" v-show="false">采购可见</el-checkbox>
        <el-upload
            :action="annexAction"
            list-type="text"
            :on-remove="annexRemove"
            :auto-upload="true"
            :before-upload="annexUpload"
            :on-error="annexError"
            :on-success="annexSuccess"
            :multiple="false"
            :limit="9"
            :on-exceed="annexExceed"
            name="file"
            :data="annexData"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </div>

      <div class="content-main-title">
        <span>询单明细列表</span>
        <div class="hr"></div>
      </div>
      <div class="inquiry-details-list">
        <div class="inquiry-details-list-title">
          <ul>
            <li>
              <span>品牌（中文）</span>
              <el-input
                  v-model="tableOperating.brand_ch"
                  placeholder="请输入"
                  @change="tableChane('brand_ch','品牌（中文）',tableOperating.brand_ch)"
              >

              </el-input>
            </li>
            <li>
              <span>品牌（英文）</span>
              <el-input
                  v-model="tableOperating.brand_en"
                  placeholder="请输入"
                  @change="tableChane('brand_en','品牌（英文）',tableOperating.brand_en)"
              ></el-input>
            </li>
            <li>
              <span>品名/规格型号/单位</span>
              <el-input
                  v-model="tableOperating.spec"
                  placeholder="请输入"
                  @change="tableChane('spec','品名规格型号',tableOperating.spec)"
              ></el-input>
            </li>
            <li>
              <span>数量</span>
              <el-input
                  v-model="tableOperating.number"
                  placeholder="请输入"
                  @change="tableChane('number','数量',tableOperating.number)"
              ></el-input>
            </li>
          </ul>
          <div class="but-operating">
            <el-button type="warning" @click="batchDel">批量删除</el-button>
            <el-button type="primary" @click="addseekDialog('null')">添加商品</el-button>
            <el-button type="success" @click="excelTemplateDownload">表格模板下载</el-button>
            <!--表格导入-->
            <common-excel-import :commonExcelImportProp="seek_list" @seekTableInit="seekTableInit"/>
          </div>
        </div>
      </div>

      <!--表格-->
      <div class="mt-12">
        <el-table
            ref="table"
            :data="seek_list"
            @selection-change="handleSelectionChange"
            border
            style="width: 100%"
            :row-class-name="tableRowClassName"
            :header-cell-style="{
        'color': '#18a78a'}"
        >
          <el-table-column
              type="selection"
              width="40px"
              align="left"
              :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
              label="品牌（中文）"
              prop="brand_ch"
              min-width="170px"
              align="left"
              :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
              label="品牌（英文）"
              prop="brand_en"
              min-width="170px"
              align="left"
              :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
              label="品名/规格型号/单位"
              prop="spec"
              min-width="170px"
              align="left"
              :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
              label="数量"
              prop="number"
              min-width="170px"
              align="left"
              :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="操作" width="180px" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="seekTableEdit(scope.row.index)">
                编辑
              </el-button>
              <el-button type="danger" @click="seekTableDel(scope.row.index)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!--dialog销售员-->
    <el-dialog
        :append-to-body="true"
        title="选择采购员"
        :visible.sync="buyerSelectDialog"
        width="440px"
        :before-close="buyerSelectClose"
    >
      <div>12312312</div>
    </el-dialog>

    <!--dialog采购员-->
    <el-dialog
        :append-to-body="true"
        title="选择采购员"
        :visible.sync="buyerSelectDialog"
        width="440px"
        :before-close="buyerSelectClose"
    >
      <div class="buyerSelect-dialog">
        <div class="title">
          <span>已选择：</span>
          <ul>
            <template v-for="(item,key) in buyer_list">
              <li :key="key" v-if="buyer_ids.indexOf(item.id)!==-1">
                <span>{{ item.name }}</span>
                <i class="el-icon-close" @click="buyerIsSelect(item.id)"></i>
              </li>
            </template>
          </ul>
        </div>

        <div class="search">
          <el-input
              placeholder="请输入角色名"
              v-model="buyer_search"
          >
            <i slot="suffix" class="el-icon-search" @click="roleList(buyer_search)"></i>
          </el-input>
        </div>


        <div class="list">
          <el-checkbox-group v-model="buyer_ids">
            <template v-for="(item) in buyer_list">
              <el-checkbox
                  :label="item.id"
                  :key="item.id"
              >
                {{ item.name }}
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="buyerSelectClose">取 消</el-button>
        <el-button type="primary" @click="buyerSelectDialog = false">确 定</el-button>
      </div>
    </el-dialog>

    <!--询单明细列表 添加商品-->
    <el-dialog
        :append-to-body="true"
        title="添加商品"
        :visible.sync="seekDialog"
        width="440px"
        :before-close="seekClose"
        :close-on-press-escape="false"
    >
      <div class="seek-dialog">
        <el-form :model="addseek_list" status-icon :rules="rules" ref="ruleForm">
          <ul>
            <el-form-item prop="brand_ch">
              <li>
                <span>品牌（中文）</span>
                <el-input v-model="addseek_list.brand_ch" placeholder="请输入内容" disabled
                          v-if="seek_list.length!=0"></el-input>
                <el-input v-model="addseek_list.brand_ch" placeholder="请输入内容" v-else></el-input>
              </li>
            </el-form-item>
            <el-form-item prop="brand_en">
              <li>
                <span>品牌（英文）</span>
                <el-input v-model="addseek_list.brand_en" placeholder="请输入内容" disabled
                          v-if="seek_list.length!=0"></el-input>
                <el-input v-model="addseek_list.brand_en" placeholder="请输入内容" v-else></el-input>
              </li>
            </el-form-item>
            <el-form-item prop="spec">
              <li>
                <span>品名/规格型号/单位</span>
                <el-input v-model="addseek_list.spec" placeholder="请输入内容"></el-input>
              </li>
            </el-form-item>
            <el-form-item prop="number">
              <li>
                <span>数量</span>
                <el-input v-model="addseek_list.number" placeholder="请输入内容"></el-input>
              </li>
            </el-form-item>
          </ul>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="seekClose">取 消</el-button>
        <el-button type="primary" @click="seekSuccess('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {currentListData} from '@/api/module/basic'
import {addInquiryPut} from '@/api/module/addInquiry'
import CommonExcelImport from "@/components/CommonExcelImport";
// import {addInquiryPut,addInquiryEdit} from '@/api/module/addInquiry'
import XLSX from 'xlsx'
import {customerGet} from '@/api/module/customer'

export default {
  components: {CommonExcelImport},
  props: [],
  data() {
    return {
      rules: {
        brand_ch: [{required: true, message: '品牌（中文）', trigger: 'blur'}],
        brand_en: [{required: true, message: '品牌（英文）', trigger: 'blur'}],
        spec: [{required: true, message: '品名规格型号', trigger: 'blur'}],
        number: [{required: true, message: '数量', trigger: 'blur'}]
      },
      // 表格操作 询单明细列表
      tableOperating: {
        brand_ch: '',//品牌（中文）
        brand_en: '',//品牌（英文）
        spec: '',//品名规格型号
        number: '',//数量
      },

      //提交的数据
      desc_saleman: '',//询单备注
      xiaoshouName: '123',//销售端才要用，销售的名称
      // seek_no: '',//询单编号
      end_time: '',//截止时间
      saleman_id: '',//销售员
      buyer_ids: [],//采购员
      customer_name: '',//客户名称
      contacts: '',//联系人
      job: '',//职务
      phone: '',//手机号
      wx: '',//QQ/微信
      email: '',//邮箱
      screenshot: [],//询单截图
      //询单附件
      attachment: [
        // {
        //   name: '',
        //   url: ''
        // }
      ],

      buyerSelectDialog: false, //选择采购员dialog
      seekDialog: false, //询单明细列表添加商品

      //接口返回的数据
      // 采购员
      buyer_search: '', //搜索
      buyer_list: [
        /*  {
            name: 'caicai1',
            id: 1
          }*/
      ],
      //销售员id
      seller_id: 1,
      saleman_list: [],


      //询单明细列表
      seek_list: [
        /*  {
            id: 0,
            brand_ch: '1',//品牌（中文）
            brand_en: '2',//品牌（英文）
            spec: '3',//品名规格型号
            number: '4',//数量
          }*/
      ],
      //询单明细列表  添加商品
      addseek_list: {
        index: '',
        brand_ch: '',//品牌（中文）
        brand_en: '',//品牌（英文）
        spec: '',//品名规格型号
        number: '',//数量
      },
      //询单明细列表  批量删除
      batchDelArry: [],

      // 截图
      screenshotAction: process.env.VUE_APP_URL + '/api/upload/uploadFile',
      screenshotData: {remarks: 1}, //上传添加的字段
      screenshotDialog: false,
      screenshotImg: '',//点击放大dialog显示图片
      screenshotChecked: true, //截图采购可见

      //附件
      annexAction: process.env.VUE_APP_URL + '/api/upload/uploadFile',
      annexData: {remarks: 1}, //上传添加的字段
      annexName: '', //上传文件成功后文件的名称
      annexChecked: true, //附件可见

    };
  },
  created() {
    this.roleList();
    this.sellerList();
  },
  mounted() {
    if (this.$store.state.currentPermissions == 2) {
      this.saleman_id = window.sessionStorage.getItem('id');//销售员id
    }
    this.xiaoshouName = window.sessionStorage.getItem('username');//销售员名称
  },

  methods: {
    //客户名称预选
    querySearch(queryString, cb) {
      let params = {
        type: 1,
        is_paginate: 2, //  不分页
        customer_name: this.customer_name,
      }
      customerGet(params).then((result) => {
        let res = result.data;
        for (let i = 0; i < res.length; i++) {
          res[i].value = res[i].customer_name
        }
        let restaurants = res;
        console.log(restaurants)
        let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      })

    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    //客户名称被选中
    handleSelect(item) {


      this.customer_name = item.customer_name;//客户名称
      this.contacts = item.contacts;//联系人
      this.job = item.job;//职务
      this.phone = item.phone;//手机号
      this.wx = item.wx;//QQ/微信
      this.email = item.email;//邮箱
    },
    //表格导入
    excelImport() {
      console.log(123)
    },
    //表格模板下载
    excelTemplateDownload() {
      let _data = [
        ["品牌（中文）", "品牌（英文）", "品名", "规格型号", "单位", "数量"],
      ];
      const ws = XLSX.utils.aoa_to_sheet(_data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, '表格导入模板.xlsx');
    },
    //获取采购员
    roleList(name) {
      let params = {
        user_type: 1,
        lock_status: 1,
        username: name
      }
      currentListData(params).then((result) => {
        this.buyer_list = result.data.data.map(item => ({
          name: item.username,
          id: item.id
        }));
      })
    },
    //获取销售员
    sellerList() {
      let params = {
        user_type: 2,
        lock_status: 1
      }
      currentListData(params).then((result) => {
        this.saleman_list = result.data.data.map(item => ({
          value: item.id,
          label: item.username
        }));
      })
    },
    //点击提交  提交的数据
    subClick() {
      if (this.customer_name == '') {
        this.$message.error('客户名称不能为空');
        return false;
      }
      if (this.end_time == '') {
        this.$message.error('截止时间不能为空');
        return false;
      }
      if (this.saleman_id == '') {
        this.$message.error('销售员不能为空');
        return false;
      }
      if (this.buyer_ids.length == 0) {
        this.$message.error('采购员不能为空');
        return false;
      }
      if (this.customer_name == '') {
        this.$message.error('客户名称不能为空');
        return false;
      }
      if (this.contacts == '') {
        this.$message.error('联系人不能为空');
        return false;
      }
      if (this.job == '') {
        this.$message.error('职务不能为空');
        return false;
      }
      if (this.phone == '') {
        this.$message.error('手机号不能为空');
        return false;
      }
      if (this.wx == '') {
        this.$message.error('QQ/微信不能为空');
        return false;
      }
      if (this.email == '') {
        this.$message.error('邮箱不能为空');
        return false;
      }
      if (this.seek_list == '') {
        this.$message.error('询单明细列表不能为空');
        return false;
      }
      let data = {
        // seek_no: this.seek_no,//询单编号
        desc_saleman: this.desc_saleman,//询单备注
        end_time: this.end_time / 1000,//截止时间
        saleman_id: this.saleman_id,//销售员
        buyer_ids: JSON.stringify(this.buyer_ids),//采购员
        customer_name: this.customer_name,//客户名称
        contacts: this.contacts,//联系人
        job: this.job,//职务
        phone: this.phone,//手机号
        wx: this.wx,//QQ/微信
        email: this.email,//邮箱
        screenshot: this.screenshot.toString(),//询单截图
        screenshot_status: this.screenshotChecked ? 1 : 2,// 询单截图 采购 1可见 2不可以据
        attachment: JSON.stringify(this.attachment),//询单附件
        attachment_status: this.annexChecked ? 1 : 2,//询单附件 采购 1可见 2不可以据
        brand_ch: this.seek_list[0].brand_ch,//中文品牌
        brand_en: this.seek_list[0].brand_en,//英文品牌
        seek_list: JSON.stringify(this.seek_list),//询单明细列表
      }
      addInquiryPut(data).then(() => {
        this.$message.success('提交成功');
        // Object.assign(this.$data, this.$options.data.call(this))
        this.$router.push('/inquiry/inquiryList')
      })
    },
    // 操作表格全部替换
    tableChane(field, name, val) {
      this.$confirm(`批量操作<span style="color: red">${name}</span>, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        for (let i = 0; i < this.seek_list.length; i++) {
          this.seek_list[i][field] = val;
        }
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
    //采购员  选择采购员关闭dialog还原数据
    buyerSelectClose() {
      this.buyerSelectDialog = false;
      this.buyer_ids = this.$options.data().buyer_ids;
    },
    //采购员 删除选择
    buyerIsSelect(id) {
      for (let i = 0; i < this.buyer_ids.length; i++) {
        if (this.buyer_ids[i] == id) {
          this.buyer_ids.splice(i, 1);
        }
      }
    },

    //截图 放大缩小
    screenshotPreview(file) {
      this.screenshotImg = file.url;
      this.screenshotDialog = true;
    },
    //截图 移除文件时的钩子
    screenshotRemove(file) {
      for (let i = 0; i < this.screenshot.length; i++) {
        if (file.response.data == this.screenshot[i]) {
          this.screenshot.splice(i, 1);
        }
      }
    },
    //截图 上传失败的钩子
    screenshotError() {
      this.$message.error('截图上传失败上传失败');
    },
    //截图 上传成功钩子
    screenshotSuccess(file) {
      this.screenshot.push(file.data)
    },
    //截图 上传文件之前的钩子
    screenshotUpload(file) {
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    //截图 文件超出个数限制时的钩子
    screenshotExceed(files, fileList) {
      this.$message.error(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },

    //附件 移除文件时的钩子
    annexRemove(file) {
      for (let i = 0; i < this.attachment.length; i++) {
        if (file.response.data == this.attachment[i]) {
          this.attachment.splice(i, 1);
        }
      }
    },
    //附件 上传失败的钩子
    annexError() {
      this.$message.error('附件上传失败上传失败');
    },
    //附件 上传成功钩子
    annexSuccess(file) {
      let data = {
        name: this.annexName,
        url: file.data
      }
      this.attachment.push(data)
    },
    //附件 上传文件之前的钩子
    annexUpload(file) {
      console.log(file)
      this.annexName = file.name;//名字存起来，传给后台要用
      const isText = (file.type === 'application/vnd.ms-excel' || file.type === 'application/pdf' || file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/msword' || file.type === 'text/plain' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
      if (!isText) {
        this.$message.error('上传附件格式错误!');
      }
      return isText;
    },
    //附件 文件超出个数限制时的钩子
    annexExceed(files, fileList) {
      this.$message.error(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },

    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },
    //表格，id重置
    seekTableInit() {
      for (let i = 0; i < this.seek_list.length; i++) {
        this.seek_list[i].index = i;
      }
    },
    //表格 多选后的值
    handleSelectionChange(selection) {
      let tableDataROW = [];
      selection.forEach((value) => {
        this.seek_list.forEach((val) => {
          if (value.index == val.index) {
            tableDataROW.push(val.index);
          }
        });
      });
      this.batchDelArry = tableDataROW;
      this.batchDelArry.sort(function (p1, p2) {
        return p2 - p1
      })
    },
    //表格 编辑
    seekTableEdit(id) {
      console.log(id)
      this.addseek_list.index = id;
      this.addseek_list = this.seek_list[id];
      this.seekDialog = true;
      if (this.seek_list.length != 0) {
        this.addseek_list.brand_ch = this.seek_list[0].brand_ch;
        this.addseek_list.brand_en = this.seek_list[0].brand_en;
      }
    },
    //表格 删除
    seekTableDel(id) {
      this.$confirm('此操作将永久删除, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.seek_list.splice(id, 1);
        this.seekTableInit();
        this.$message({
          type: 'warning',
          message: '操作成功，需提交保存!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //表格 批量删除
    batchDel() {
      if (this.batchDelArry.length == 0) {
        this.$message.error('请选择要删除的信息');
        return false;
      }
      this.$confirm('此操作将永久删除, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        for (let i = 0; i < this.batchDelArry.length; i++) {
          for (let j = 0; j < this.seek_list.length; j++) {
            if (this.batchDelArry[i] == this.seek_list[j].index) {
              this.seek_list.splice(j, 1);
            }
          }
        }
        this.seekTableInit();
        // for (let i = this.batchDelArry.length - 1; i >= 0; i--) {
        //   this.seek_list.splice(i - 1, 1);
        // }
        this.batchDelArry = [];
        this.$message({
          type: 'warning',
          message: '操作成功，需提交保存!'
        });
      }).catch(() => {
        console.log(this.batchDelArry)
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //询单明细列表 dialog 点击添加商品
    addseekDialog(index) {
      this.addseek_list.index = index;
      if (this.seek_list.length != 0) {
        this.addseek_list.brand_ch = this.seek_list[0].brand_ch;
        this.addseek_list.brand_en = this.seek_list[0].brand_en;
      }
      this.seekDialog = true;
    },
    //询单明细列表 dialog 取消或关闭
    seekClose() {
      this.seekDialog = false;
      this.addseek_list = this.$options.data().addseek_list;
    },
    //询单明细列表 dialog 点击确定
    seekSuccess(ruleForm, index) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          if (this.addseek_list.index == 'null') {
            this.seek_list.push(this.addseek_list);
            this.seekTableInit();
          } else {
            this.seek_list[index] = {...this.addseek_list};
            this.seekTableInit();
          }
          this.seekClose();
        }
      })

    }

  },
};
</script>
<style lang="scss">
.seek-dialog {
  .el-form-item__error {
    margin-left: 100px;
  }
}
</style>
<style lang="scss" scoped>


//表单
.inquiryIn-formation-buyer {
  @include flex(row, wrap, center, flex-start);
  padding: 8px;

  li {
    margin-right: 8px;
  }

  i {
    color: red;
    margin-left: 8px;
    cursor: pointer;
  }
}


//询单明细列表
.inquiry-details-list-title {
  @include flex(row, nowrap, center, space-between);

  ul {
    @include flex();

    li {
      @include flex();

      span {

        text-align: center;
        margin: 0 8px;
        color: #7f8789;
      }

      .el-input {
        width: 120px;
      }
    }
  }
}

//询单明细列表 添加商品dialog
.seek-dialog {
  li {
    margin-bottom: 12px;
    @include flex();

    span {
      width: 100px;
      flex: 0 0 auto;
    }

    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }

}

</style>
<style lang="scss">
//截图上传
.screenshot-upload {
  .el-upload--picture-card, .el-upload-list__item {
    width: 120px;
    height: 120px;
    line-height: 120px;
  }

  .el-icon-check {
    position: absolute;
    left: 14px;
    top: 0px;
  }
}

//附件上传
.annex-upload {
  .el-upload-list {
    @include flex(row, wrap, center, flex-start);

    li {
      width: 150px;
    }
  }
}

//选择采购员
.buyerSelect-dialog {
  .title {
    @include flex();

    & > span {
      width: 60px;
      flex: 0 0 auto;
    }

    ul {
      @include flex(row, wrap, center, flex-start);

      li {
        margin-right: 12px;

        i {
          margin-left: 5px;
          color: red;
          cursor: pointer;
        }
      }
    }
  }

  .search {
    margin-top: 12px;

    .el-icon-search {
      color: #05c69a;
      font-size: 16px;
      line-height: 32px;
      cursor: pointer;
    }
  }

  .list {
    .el-checkbox:nth-of-type(even) {
      background: #C9F5F2;
    }

    .el-checkbox-group {
      background: #E3FEFB;
      margin-top: 8px;
    }

    .el-checkbox {
      @include flex();
      width: calc(100% - 16px);
      padding: 8px;
    }
  }
}

</style>
